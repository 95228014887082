import { combineReducers, configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/userSlice'
import carrierReducer from './reducers/carrierSlice'
import taskReducer from './reducers/taskSlice'
import executeReducer from './reducers/executeSlice'
import { authAPI } from 'api/authAPI'
import { userAPI } from 'api/userAPI'
import { taskAPI } from 'api/taskAPI'

const rootReducer = combineReducers({
  userReducer,
  carrierReducer,
  taskReducer,
  executeReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [taskAPI.reducerPath]: taskAPI.reducer
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(authAPI.middleware, userAPI.middleware, taskAPI.middleware)
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
