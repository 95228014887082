import * as React from 'react'
import { Alert, Breadcrumbs, CircularProgress, Grid } from '@mui/material'
import { FC, useEffect } from 'react'
import Button from '@mui/material/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'
import { useCreateTaskMutation, useGetAllTasksQuery } from 'api/taskAPI'
import { initialTask } from '../constants/constants'
import TasksList from 'components/ui/TasksList'

export const TasksListPage: FC = () => {
  const navigate = useNavigate()
  const { data: allTasksData } = useGetAllTasksQuery()
  const [createTask, { data: response, isLoading: createTaskLoading, error: createTaskError }] = useCreateTaskMutation()
  const createTaskHandler = () => {
    createTask(initialTask)
  }
  useEffect(() => {
    if (response && !createTaskLoading && !createTaskError) {
      navigate(`/home/tasks/${response!.id}`)
    }
  }, [response, createTaskLoading, createTaskError])

  return (
    <>
      <Grid container>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link style={{ textDecoration: 'none' }} to={'/home'}>
            Home
          </Link>
          <Typography color='text.primary'>Tasks</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container paddingY={3}>
        {!createTaskLoading && !createTaskError ? (
          <Button
            variant='contained'
            color='secondary'
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={createTaskHandler}
          >
            <AddOutlinedIcon />
            Create Task
          </Button>
        ) : createTaskLoading && !createTaskError ? (
          <CircularProgress />
        ) : (
          <Alert severity='error'>{(createTaskError as any).data.message}</Alert>
        )}
      </Grid>
      <Grid container>
        <TasksList allTasksData={allTasksData} />
      </Grid>
    </>
  )
}
