import { BaseQueryFn, createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { ICarriers, ICreateTask, IExecutingHistory, IScheduleOptions, ITask, UserCredentialsType } from '../types/types'
import { setTask } from 'store/reducers/taskSlice'
import { initialTask } from '../constants/constants'
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const baseUrl = process.env.REACT_APP_BASE_URL

export const taskAPI = createApi({
  reducerPath: 'taskAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['getInvoiceVsp', 'getInvoiceKaiser', 'getTask', 'getAllTask', 'getExecutingHistory'],
  endpoints: builder => ({
    createTask: builder.mutation<ITask, ICreateTask>({
      query: body => ({
        url: 'task/create-task',
        method: 'POST',
        body: body
      }),
      transformResponse: (res: ITask) => res
    }),
    getAllTasks: builder.query<ITask[] | ITask, void>({
      query: () => `task/all`,
      transformResponse: (res: ITask[] | ITask) => res,
      providesTags: ['getAllTask']
    }),
    getTask: builder.query<ITask, number>({
      query: id => `task/get-task/${id}`,
      transformResponse: (res: ITask) => res,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTask(data))
        } catch (err) {}
      },
      providesTags: ['getTask']
    }),
    updateTask: builder.mutation<ITask, ITask>({
      query: body => ({
        url: `task/update-task/${body.id}`,
        method: 'PUT',
        body: body
      }),
      transformResponse: (res: ITask) => res,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTask(data))
        } catch (err) {}
      },
      invalidatesTags: ['getAllTask', 'getTask']
    }),
    deleteTask: builder.mutation<void, number>({
      query: id => ({
        url: `task/delete-task/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(setTask(initialTask))
        } catch (err) {}
      },
      invalidatesTags: ['getAllTask', 'getTask']
    }),
    getAllCarriers: builder.query<ICarriers[] | ICarriers, void>({
      query: () => `task/carriers-all`,
      transformResponse: (res: ICarriers[] | ICarriers) => res
    }),
    getClientsVSP: builder.query<string[], UserCredentialsType>({
      query: options => `task/get-clients-vsp/?username=${options.username}&password=${options.password}`,
      transformResponse: (res: string[]) => res
    }),
    getClientsKaiser: builder.query<string[], UserCredentialsType>({
      query: options => `task/get-clients-kaiser/?username=${options.username}&password=${options.password}`,
      transformResponse: (res: string[]) => res
    }),
    getInvoiceVsp: builder.query<any, number>({
      query: id => `task/get-invoice-vsp/${id}`,
      providesTags: () => ['getInvoiceVsp'],
      transformResponse: (res: any) => res
    }),
    getInvoiceKaiser: builder.query<any, number>({
      query: id => `task/get-invoice-kaiser/${id}`,
      providesTags: () => ['getInvoiceKaiser'],
      transformResponse: (res: any) => res
    }),
    runSchedule: builder.mutation<any, IScheduleOptions>({
      query: body => ({
        url: 'task/run-schedule',
        body: body,
        method: 'POST'
      }),
      transformResponse: (res: any) => res,
      invalidatesTags: ['getAllTask', 'getTask']
    }),
    removeSchedule: builder.mutation<any, number>({
      query: id => ({
        url: `task/remove-schedule/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (res: any) => res,
      invalidatesTags: ['getAllTask', 'getTask']
    }),
    getExecutingHistory: builder.query<IExecutingHistory[], void>({
      query: () => `task/get-executing-history`,
      transformResponse: (res: IExecutingHistory[]) => res,
      providesTags: () => ['getExecutingHistory']
    })
  })
})
export const {
  useCreateTaskMutation,
  useGetAllTasksQuery,
  useDeleteTaskMutation,
  useGetTaskQuery,
  useGetAllCarriersQuery,
  useUpdateTaskMutation,
  useLazyGetInvoiceVspQuery,
  useLazyGetInvoiceKaiserQuery,
  useRunScheduleMutation,
  useRemoveScheduleMutation,
  useLazyGetExecutingHistoryQuery,
  useLazyGetClientsVSPQuery,
  useLazyGetClientsKaiserQuery
} = taskAPI
