import { Autocomplete, Box, Button, Chip, FormControl, Modal, TextField, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { toast } from 'react-toastify'

interface AdditionalEmailsModalProps {
  isOpen: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}
const style = {
  position: 'absolute' as 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minwidth: 400,
  bgcolor: '#FFF',
  boxShadow: 24,
  p: 4
}
export default function AdditionalEmailsModal({ isOpen, setOpenModal }: AdditionalEmailsModalProps) {
  const [emails, setEmails] = useState([])

  const handleClose = () => {
    setOpenModal(false)
    setEmails([])
  }
  const changeHandler = (value: any) => {
    setEmails(value)
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <FormControl>
          <Typography sx={{ fontSize: '22px', fontWeight: 700, color: '#000' }}>Email destination:</Typography>
          <Typography pb={2} sx={{ fontSize: '20px', fontWeight: 300, color: '#000' }}>
            The invoices will be delivered to the account owner.
            <br />
            If you would like to email them to an additional address, type it in the field below:
          </Typography>
          <Autocomplete
            defaultValue={emails || []}
            multiple
            freeSolo
            options={[]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant='outlined' label={option} {...getTagProps({ index })} />)
            }
            onChange={(event, values) => {
              changeHandler(values)
            }}
            renderInput={params => <TextField {...params} label='Emails' placeholder='Add emails' />}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, mt: '14px' }}>
            <Button
              sx={{ textTransform: 'none', fontSize: '18px', fontWeight: '700' }}
              variant='contained'
              color='success'
              startIcon={<PlayArrowIcon />}
              onClick={() => {
                handleClose()
                toast.success(`Task successfully launched`)
              }}
            >
              Execute
            </Button>
          </Box>
        </FormControl>
      </Box>
    </Modal>
  )
}
