import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Alert, Breadcrumbs, CircularProgress, Grid, IconButton, Modal } from '@mui/material'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import Box from '@mui/material/Box'
import { initialTask, styleModal } from '../constants/constants'
import {
  useDeleteTaskMutation,
  useGetTaskQuery,
  useLazyGetInvoiceKaiserQuery,
  useLazyGetInvoiceVspQuery,
  useUpdateTaskMutation
} from 'api/taskAPI'
import TaskTable from 'components/ui/TaskTable'
import { toast } from 'react-toastify'
import ScheduleModal from 'components/ui/ScheduleModal'
import { useDispatch, useSelector } from 'react-redux'
import { addTaskId, removeTaskId } from 'store/reducers/executeSlice'
import { RootState } from 'store/store'

const TaskPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const [taskState, setTaskState] = useState(initialTask)
  const [isTaskEqual, setIsTaskEqual] = useState(false)
  const [showExecuteButton, setShowExecuteButton] = useState(true)
  const { data: task, isError: getTaskError, isLoading: isTaskLoading } = useGetTaskQuery(+id!)
  const [deleteTask, { isLoading: isDeleteLoading, error: deleteError }] = useDeleteTaskMutation()
  const [updateTask, { isLoading: isUpdateLoading, error: updateError }] = useUpdateTaskMutation()
  const [getInvoiceVsp, { isFetching: invoiceVspQuering }] = useLazyGetInvoiceVspQuery()
  const [getInvoiceKaiser, { isFetching: invoiceKaiserQuering }] = useLazyGetInvoiceKaiserQuery()
  const [openScheduleModal, setOpenScheduleModal] = useState(false)
  const [runScheduleLoading, setRunScheduleLoading] = useState(false)
  const [isExecuting, setIsExecuting] = useState(false)
  const handleScheduleModalOpen = () => {
    setOpenScheduleModal(true)
  }
  const dispatch = useDispatch()
  const taskIds = useSelector((state: RootState) => state.executeReducer)
  const handleOpen = () => setIsEdit(true)
  const handleClose = () => setIsEdit(false)
  const handleTaskHeaders = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const formData = {
      taskname: data.get('taskname') as string,
      description: data.get('description') as string
    }
    if (taskState) {
      if (taskState.taskname !== formData.taskname) {
        setTaskState(prevState => ({
          ...prevState!,
          taskname: formData.taskname || ''
        }))
      }
      if (taskState.description !== formData.description) {
        setTaskState(prevState => ({
          ...prevState!,
          description: formData.description || ''
        }))
      }
    }
    handleClose()
  }

  const handleUpdate = async () => {
    await updateTask(taskState)
    navigate('/home/tasks')
  }

  const handleDelete = async () => {
    await deleteTask(+id!)
    setTaskState(initialTask)
    navigate('/home/tasks')
  }

  const handleExecute = async () => {
    let response

    if (taskState.carrier === 'VSP') {
      if (taskState.id) {
        dispatch(addTaskId(id))
        await updateTask(taskState)
        response = await getInvoiceVsp(+taskState.id)
      }
    } else if (taskState.carrier === 'Kaiser Permanente') {
      if (taskState.id) {
        dispatch(addTaskId(id))
        await updateTask(taskState)
        response = await getInvoiceKaiser(+taskState.id)
      }
    }
    if (response?.isError) {
      toast.error(`${(response.error as any).data?.message}`)
      dispatch(removeTaskId(id))
    } else if (response?.isSuccess) {
      toast.success(`${response?.data?.message}`)
      dispatch(removeTaskId(id))
    }
  }

  const isQuerying = invoiceVspQuering || invoiceKaiserQuering
  const nullableFields = () => {
    const { carrier, username, password, clientname, invoiceformat } = taskState
    return { carrier, username, password, clientname, invoiceformat }
  }

  const areObjectsEqual = (objA: any, objB: any): boolean => {
    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    if (keysA.length !== keysB.length) {
      return false
    }
    for (const key of keysA) {
      const valueA = objA[key]
      const valueB = objB[key]
      if ((valueA === null || valueA === undefined) && (valueB === null || valueB === undefined)) {
        continue
      }
      if (valueA !== valueB) {
        return false
      }
    }
    return true
  }

  const notNullNotEmpty = Object.values(nullableFields()).some(val => !val && val === '')

  useEffect(() => {
    if (task) {
      setTaskState(task)
    }
  }, [task])

  useEffect(() => {
    if (task) {
      setIsTaskEqual(areObjectsEqual(taskState, task))
    }
  }, [task, taskState])

  useEffect(() => {
    if (getTaskError) {
      navigate('/home/tasks')
      toast.error('Task not found')
    }
  }, [getTaskError])

  useEffect(() => {
    if (taskIds.includes(id!)) {
      setIsExecuting(true)
    } else {
      setIsExecuting(false)
    }
  }, [id, taskIds])

  useEffect(() => {
    if (!taskState.invoiceformat) {
      setShowExecuteButton(false)
    } else {
      setShowExecuteButton(true)
    }
  }, [taskState])

  return (
    <>
      <Grid container py={'24px'}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link style={{ textDecoration: 'none' }} to={'/home'}>
            Home
          </Link>
          <Link style={{ textDecoration: 'none' }} to={'/home/tasks'}>
            Tasks
          </Link>
          <Typography color='text.primary'>{taskState?.taskname}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container py={'24px'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color='text.primary' variant={'h4'} fontWeight={'900'} position={'relative'}>
            {taskState?.taskname}
            <svg
              onClick={handleOpen}
              style={{ position: 'absolute', top: '-35%', right: '-50%', cursor: 'pointer' }}
              xmlns='http://www.w3.org/2000/svg'
              width='22'
              height='22'
              viewBox='0 0 22 22'
              fill='none'
            >
              <path
                d='M7.5 3.49976H2C1.44772 3.49976 1 3.94747 1 4.49976V19.4998C1 20.052 1.44772 20.4998 2 20.4998H18C18.5523 20.4998 19 20.052 19 19.4998V13.9998'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
              />
              <path
                d='M20.4957 4.24271L11.6986 13.0398C11.6711 13.0673 11.6377 13.0879 11.6009 13.1002L7.88856 14.3376C7.69312 14.4028 7.50718 14.2169 7.57233 14.0214L8.80976 10.3091C8.82204 10.2723 8.84271 10.2388 8.87016 10.2114L17.6673 1.41428C18.4483 0.633232 19.7146 0.633233 20.4957 1.41428C21.2767 2.19533 21.2767 3.46166 20.4957 4.24271Z'
                stroke='black'
              />
              <path d='M9.18213 10.6067L11.3034 12.728' stroke='black' />
              <path d='M16.96 2.82861L19.0813 4.94993' stroke='black' />
            </svg>
          </Typography>
          <Typography color='text.primary' variant={'subtitle1'} mt={'10px'}>
            {taskState?.description}
          </Typography>

          <Modal
            open={isEdit}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={styleModal} component='form' noValidate onSubmit={handleTaskHeaders}>
              <IconButton color='primary' sx={{ display: 'flex', ml: 'auto' }} onClick={handleClose}>
                <CloseOutlinedIcon />
              </IconButton>
              <Typography color='text.primary' variant={'h5'} textAlign={'center'} my={'10px'}>
                Edit task name and description
              </Typography>
              <TextField
                variant='standard'
                label='Task Name'
                margin='normal'
                fullWidth
                id='taskname'
                name='taskname'
                autoFocus
              />
              <TextField
                variant='standard'
                margin='normal'
                label='Description'
                fullWidth
                id='description'
                name='description'
              />
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                Ok
              </Button>
            </Box>
          </Modal>
        </Grid>
        <Grid item display={'flex'} gap={1}>
          {!isTaskLoading
            ? !isTaskEqual &&
              !isUpdateLoading &&
              !updateError && (
                <Button
                  variant='contained'
                  disabled={notNullNotEmpty}
                  color='warning'
                  startIcon={<SaveAsOutlinedIcon />}
                  onClick={handleUpdate}
                >
                  Save
                </Button>
              )
            : null}
          {isUpdateLoading && !updateError && <CircularProgress />}

          {!isDeleteLoading && !deleteError ? (
            <Button variant='contained' color='error' startIcon={<DeleteForeverOutlinedIcon />} onClick={handleDelete}>
              Delete
            </Button>
          ) : isDeleteLoading && !deleteError ? (
            <CircularProgress />
          ) : (
            <Alert severity='error'>{(deleteError as any).data.message}</Alert>
          )}
          {/* <Button
            variant='contained'
            startIcon={<AccessTimeOutlinedIcon />}
            onClick={e => {
              e.stopPropagation()
              handleScheduleModalOpen()
            }}
          >
            {taskState.schedule ? 'Schedule is running' : 'Schedule'}
          </Button> */}
          <ScheduleModal
            isOpen={openScheduleModal}
            task={taskState!}
            setOpenModal={setOpenScheduleModal}
            setRunScheduleLoading={setRunScheduleLoading}
          />
          {isQuerying || isExecuting ? <CircularProgress /> : null}
          {showExecuteButton && !isQuerying && taskState.carrier && !isExecuting && (
            <Button
              variant='contained'
              color='success'
              startIcon={<PlayArrowOutlinedIcon />}
              disabled={notNullNotEmpty}
              onClick={e => {
                e.stopPropagation()
                handleExecute()
              }}
            >
              Execute
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {task ? <TaskTable setTaskState={setTaskState} taskState={taskState} /> : <CircularProgress />}
      </Grid>
    </>
  )
}

export default TaskPage
