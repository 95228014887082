import React, { FC, useEffect, useState } from 'react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useNavigate } from 'react-router-dom'
import { useLazyGetInvoiceKaiserQuery, useLazyGetInvoiceVspQuery } from 'api/taskAPI'
import { ITask } from '../../types/types'
import { toast } from 'react-toastify'
import TaskRow from 'components/ui/TaskRow'
import { addTaskId, removeTaskId } from 'store/reducers/executeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { CircularProgress } from '@mui/material'

interface TasksListProps {
  allTasksData: ITask | ITask[] | undefined
}

const TasksList: FC<TasksListProps> = ({ allTasksData }) => {
  const [getInvoiceVsp, { data: invoiceVspData }] = useLazyGetInvoiceVspQuery()
  const [getInvoiceKaiser, { data: invoiceKaiserData }] = useLazyGetInvoiceKaiserQuery()
  const [showExecuteButton, setShowExecuteButton] = useState(true)
  const [isExecuting, setIsExecuting] = useState(false)
  const taskIds = useSelector((state: RootState) => state.executeReducer)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleExecute = async (task: ITask) => {
    let response
    if (task.carrier === 'VSP') {
      if (task.id) {
        dispatch(addTaskId(String(task.id)))
        response = await getInvoiceVsp(+task.id)
      }
    } else if (task.carrier === 'Kaiser Permanente') {
      if (task.id) {
        dispatch(addTaskId(String(task.id)))
        response = await getInvoiceKaiser(+task.id)
      }
    }

    if (response?.isError) {
      toast.error(`${(response.error as any).data?.message}`)
      dispatch(removeTaskId(String(task.id)))
    } else if (response?.isSuccess) {
      toast.success(`${response?.data?.message}`)
      dispatch(removeTaskId(String(task.id)))
    }
  }

  useEffect(() => {
    if (invoiceVspData || invoiceKaiserData) {
      setShowExecuteButton(false)
      const timeoutId = setTimeout(() => {
        setShowExecuteButton(true)
      }, 3000)
      return () => clearTimeout(timeoutId)
    }
  }, [invoiceVspData, invoiceKaiserData])

  useEffect(() => {
    Array.isArray(allTasksData) &&
      allTasksData!.map((task: ITask) => {
        if (taskIds.includes(String(task.id!))) {
          setIsExecuting(true)
        } else {
          setIsExecuting(false)
        }
        if (!task.invoiceformat) {
          setShowExecuteButton(false)
        } else {
          setShowExecuteButton(true)
        }
      })
  }, [allTasksData, taskIds])

  return (
    <>
      {allTasksData ? (
        <TableContainer component={Paper}>
          <Table sx={{ border: '1px solid #D1D5DB' }} aria-label='task list'>
            <TableHead sx={{ backgroundColor: '#D1DEE0' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }}>Task name</TableCell>
                <TableCell sx={{ fontWeight: 'bolder' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bolder' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(allTasksData) &&
                allTasksData.map(task => (
                  <TaskRow
                    key={task.id}
                    task={task}
                    onEditClick={() => navigate(`/home/tasks/${task.id}`)}
                    onExecuteClick={async e => {
                      e.stopPropagation()
                      await handleExecute(task)
                    }}
                    showExecuteButton={showExecuteButton}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default TasksList
