import React, { FC, useEffect, useState } from 'react'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import FormControl from '@mui/material/FormControl'
import { ITask } from '../../types/types'
import { useGetAllCarriersQuery, useLazyGetClientsKaiserQuery, useLazyGetClientsVSPQuery } from 'api/taskAPI'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { toast } from 'react-toastify'

interface TaskTableProps {
  taskState: ITask
  setTaskState: React.Dispatch<React.SetStateAction<ITask>>
}
const TaskTable: FC<TaskTableProps> = ({ taskState, setTaskState }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showUsername, setShowUsername] = useState(false)
  const [open, setOpen] = useState(false)
  const [clients, setClients] = useState<string[]>([])
  const { data: carriers } = useGetAllCarriersQuery()
  const [getClientsVSP] = useLazyGetClientsVSPQuery()
  const [getClientsKaiser] = useLazyGetClientsKaiserQuery()
  const loading = open && clients.length === 0

  enum InvoiceFormatEnum {
    CSV = 'csv',
    XLSX = 'xlsx',
    PDF = 'pdf'
  }
  function handleChangeTask(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>
  ): void {
    setTaskState(prevState =>
      event.target
        ? {
            ...prevState,
            [event.target.name]: event.target.value
          }
        : prevState
    )
    if (event.target.name === 'carrier') {
      setTaskState(prevState => ({
        ...prevState!,
        taskname: event.target.value
      }))
    }
  }

  const handleClickShowUsername = () => setShowUsername(show => !show)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  useEffect(() => {
    const options = {
      username: taskState.username!,
      password: taskState.password!
    }
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      if (active) {
        let response
        switch (taskState.carrier) {
          case 'VSP': {
            response = await getClientsVSP(options)
            break
          }
          case 'Kaiser Permanente': {
            response = await getClientsKaiser(options)
            break
          }
          default: {
            return null
          }
        }
        if (response.data && response.data.length > 0) {
          setClients([...response.data.filter((client: string) => client !== 'Select One')])
        }
        if (response.isError) {
          toast.error((response.error as any).data.message)
          return () => {
            active = false
          }
        }
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>#</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Carrier</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Username</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Password</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Client name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Invoice Format</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row'>
              <Typography variant={'subtitle2'} component={'p'} sx={{ mt: '16px' }}>
                1
              </Typography>
            </TableCell>
            <TableCell>
              <FormControl variant='standard' sx={{ width: '100%', minWidth: 100 }}>
                <InputLabel id='carrier'>Select Carrier</InputLabel>
                <Select
                  labelId='carrier'
                  id='carrier'
                  name='carrier'
                  value={taskState.carrier ?? ''}
                  label='carrier'
                  margin={'none'}
                  sx={{ textAlign: 'left' }}
                  onChange={event => handleChangeTask(event)}
                >
                  {Array.isArray(carriers) &&
                    carriers.map(carrier => (
                      <MenuItem key={carrier.id} value={carrier.carrier}>
                        {carrier.carrier}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell sx={{ position: 'relative' }}>
              <TextField
                onChange={event => handleChangeTask(event)}
                sx={{ width: '100%' }}
                id='username'
                name='username'
                value={taskState.username ?? ''}
                label='Enter Username'
                variant='standard'
                type={showUsername ? 'text' : 'password'}
              />
              <IconButton
                sx={{ position: 'absolute', top: '50%', right: '5%', transform: 'translateY(-40%)' }}
                aria-label='toggle username visibility'
                onClick={handleClickShowUsername}
                onMouseDown={handleMouseDown}
                edge='end'
              >
                {showUsername ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ position: 'relative' }}>
              <TextField
                onChange={event => handleChangeTask(event)}
                sx={{ width: '100%' }}
                id='password'
                name='password'
                value={taskState.password ?? ''}
                label='Enter Password'
                variant='standard'
                type={showPassword ? 'text' : 'password'}
              />
              <IconButton
                sx={{ position: 'absolute', top: '50%', right: '5%', transform: 'translateY(-40%)' }}
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </TableCell>
            <TableCell>
              <Autocomplete
                sx={{ width: '100%', minWidth: '150px' }}
                open={open}
                onOpen={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setOpen(true)
                }}
                onClose={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setOpen(false)
                }}
                value={taskState.clientname || null}
                onChange={(event, value) => {
                  setTaskState(prevState =>
                    event.target
                      ? {
                          ...prevState,
                          clientname: value
                        }
                      : prevState
                  )
                }}
                isOptionEqualToValue={(client, value) => client === value}
                getOptionLabel={client => client}
                options={clients}
                loading={loading}
                disabled={!taskState.username || !taskState.password}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Clients'
                    variant='standard'
                    onFocus={e => e.stopPropagation()}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <FormControl variant='standard' sx={{ width: '100%', minWidth: 100 }}>
                <InputLabel id='invoiceformat-label'>Select Format</InputLabel>
                <Select
                  labelId='invoiceformat'
                  id='invoiceformat'
                  name='invoiceformat'
                  value={taskState.invoiceformat ?? ''}
                  label='invoiceformat'
                  margin={'none'}
                  sx={{ textAlign: 'left' }}
                  disabled={!taskState.carrier || !taskState.username || !taskState.password || !taskState.clientname}
                  onChange={event => handleChangeTask(event)}
                >
                  <MenuItem value={InvoiceFormatEnum.CSV}>{InvoiceFormatEnum.CSV}</MenuItem>
                  <MenuItem value={InvoiceFormatEnum.PDF}>{InvoiceFormatEnum.PDF}</MenuItem>
                  {taskState.carrier !== 'VSP' && (
                    <MenuItem value={InvoiceFormatEnum.XLSX}>{InvoiceFormatEnum.XLSX}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TaskTable
