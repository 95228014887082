import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSObject } from '@emotion/react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CircularProgress, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import ScheduleModal from 'components/ui/ScheduleModal'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import AdditionalEmailsModal from './AdditionalEmailsModal'

interface TaskRowProps {
  task: any
  onEditClick: (e: any) => void
  onExecuteClick: (e: any) => void
  showExecuteButton: boolean
}

const TaskRow: FC<TaskRowProps> = ({ task, onEditClick, onExecuteClick, showExecuteButton }) => {
  const [openModal, setOpenModal] = useState(false)
  const [addEmailsOpen, setAddEmailsOpen] = useState(false)
  const [runScheduleLoading, setRunScheduleLoading] = useState(false)
  const [isExecuting, setIsExecuting] = useState(false)
  const navigate = useNavigate()
  const hoverStyles: CSSObject = {
    '&:last-child td, &:last-child th': { border: 0 },
    '&:hover': { backgroundColor: '#D1D5DB', cursor: 'pointer' }
  }
  const taskIds = useSelector((state: RootState) => state.executeReducer)
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpenModal(true)
  }

  useEffect(() => {
    if (taskIds.includes(String(task.id))) {
      setIsExecuting(true)
    } else {
      setIsExecuting(false)
    }
  }, [task.id, taskIds])
  return (
    <>
      <TableRow
        sx={hoverStyles}
        onClick={() => {
          navigate(`/home/tasks/${task.id}`)
        }}
      >
        <TableCell>{task.taskname}</TableCell>
        <TableCell>{task.description}</TableCell>
        <TableCell sx={{ width: '30%' }}>
          <Grid container gap={2}>
            <Button
              variant='contained'
              color='warning'
              startIcon={
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 22 22' fill='none'>
                  <path
                    d='M7.5 3.49976H2C1.44772 3.49976 1 3.94747 1 4.49976V19.4998C1 20.052 1.44772 20.4998 2 20.4998H18C18.5523 20.4998 19 20.052 19 19.4998V13.9998'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                  />
                  <path
                    d='M20.4957 4.24271L11.6986 13.0398C11.6711 13.0673 11.6377 13.0879 11.6009 13.1002L7.88856 14.3376C7.69312 14.4028 7.50718 14.2169 7.57233 14.0214L8.80976 10.3091C8.82204 10.2723 8.84271 10.2388 8.87016 10.2114L17.6673 1.41428C18.4483 0.633232 19.7146 0.633233 20.4957 1.41428C21.2767 2.19533 21.2767 3.46166 20.4957 4.24271Z'
                    stroke='white'
                  />
                  <path d='M9.18213 10.6067L11.3034 12.728' stroke='white' />
                  <path d='M16.96 2.82861L19.0813 4.94993' stroke='white' />
                </svg>
              }
              onClick={onEditClick}
            >
              Edit
            </Button>
            {!runScheduleLoading ? (
              <Button variant='contained' startIcon={<AccessTimeOutlinedIcon />} onClick={handleOpen}>
                {task.schedule ? 'Schedule is running' : 'Schedule'}
              </Button>
            ) : (
              <CircularProgress />
            )}

            {isExecuting ? <CircularProgress /> : null}

            {showExecuteButton && task?.carrier && !isExecuting && (
              <Button
                variant='contained'
                color='success'
                startIcon={<PlayArrowOutlinedIcon />}
                onClick={onExecuteClick}
              >
                Execute
              </Button>
            )}
          </Grid>
        </TableCell>
      </TableRow>
      <ScheduleModal
        isOpen={openModal}
        task={task}
        setOpenModal={setOpenModal}
        setRunScheduleLoading={setRunScheduleLoading}
        addEmails={setAddEmailsOpen}
      />
      <AdditionalEmailsModal isOpen={addEmailsOpen} setOpenModal={setAddEmailsOpen} />
    </>
  )
}

export default TaskRow
