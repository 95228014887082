import { ICarrier } from '../../types/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ICarrier = {
  id: null,
  carriers: null,
  clients: null
}

export const carrierSlice = createSlice({
  name: 'carier',
  initialState,
  reducers: {
    setCarrier: (state, action) => {
      return action.payload
    }
  }
})
export const { setCarrier } = carrierSlice.actions
export default carrierSlice.reducer
