import { initialTask } from '../../constants/constants'
import { ITask } from '../../types/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ITask = initialTask

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTask: (state, action) => {
      return action.payload
    }
  }
})

export const { setTask } = taskSlice.actions
export default taskSlice.reducer
