import { IScheduleTimeOptions, ITask } from '../types/types'

export const PAGE_WITH_HEADER = ['/home', '/home/tasks', '/home/tasks/new_task']

export const initialTask: ITask = {
  id: null,
  taskname: 'New Task',
  description: 'Description',
  carrier: null,
  username: null,
  password: null,
  clientname: null,
  invoiceformat: null,
  schedule: null,
  schedule_object: null,
  user: null
}

export const InitialExecute: string[] = []

export const scheduleInitial: IScheduleTimeOptions = {
  seconds: null,
  minutes: null,
  hours: null,
  dayOfMonth: null,
  month: null,
  dayOfWeek: null
}

export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4
}
