import { InitialExecute } from '../../constants/constants'
import { createSlice } from '@reduxjs/toolkit'

const initialState: string[] = InitialExecute

export const executeSlice = createSlice({
  name: 'execute',
  initialState,
  reducers: {
    addTaskId: (state, action) => {
      state.push(action.payload)
    },
    removeTaskId: (state, action) => {
      const index = state.indexOf(action.payload)
      if (index !== -1) {
        state.splice(index, 1)
      }
    }
  }
})

export const { addTaskId, removeTaskId } = executeSlice.actions
export default executeSlice.reducer
