import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useRemoveScheduleMutation, useRunScheduleMutation, useUpdateTaskMutation } from 'api/taskAPI'
import { IScheduleTimeOptions, ITask } from '../../types/types'
import { scheduleInitial } from '../../constants/constants'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
const style = {
  position: 'absolute' as 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minwidth: 400,
  bgcolor: '#FFF',
  boxShadow: 24,
  p: 4
}

interface ScheduleModalProps {
  isOpen: boolean
  task: ITask
  setOpenModal: Dispatch<SetStateAction<boolean>>
  setRunScheduleLoading: Dispatch<SetStateAction<boolean>>
  addEmails?: Dispatch<SetStateAction<boolean>>
}

function ScheduleModal({ isOpen, task, setOpenModal, setRunScheduleLoading, addEmails }: ScheduleModalProps) {
  const [runSchedule, { isLoading: runScheduleLoading }] = useRunScheduleMutation()
  const [removeSchedule, { isLoading: removeScheduleLoading }] = useRemoveScheduleMutation()
  const [updateTask] = useUpdateTaskMutation()
  const [openChildModal, setOpenChildModal] = useState(false)
  const [taskId, setTaskId] = useState<number | null>(null)
  const [valueDayjs, setValueDayjs] = useState<dayjs.Dayjs | null>(dayjs())
  const [selectedFrequency, setSelectedFrequency] = useState<string>('monthly')
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [valueTimeOptions, setValueTimeOptions] = useState<IScheduleTimeOptions>(scheduleInitial)
  const [selectedDay, setSelectedDay] = useState<number>(1)
  const [hours, setHours] = useState<number>(1)
  const [mins, setMins] = useState<number>(0)
  const [isAm, setIsAm] = useState<string>('AM')

  // const isAM = valueTimeOptions.hours !== null && valueTimeOptions.hours < 12
  // const amPmText = isAM ? 'AM' : 'PM'

  const frequencyArray = ['monthly', 'weekly', 'daily']
  const daysArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'sunday', 'saturday']
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ]
  const handleClose = () => {
    setOpenModal(false)
    setTaskId(null)
    setValueTimeOptions(scheduleInitial)
  }

  const handleChildOpen = () => setOpenChildModal(true)
  const handleChildClose = () => setOpenChildModal(false)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueCheckbox = event.target.value
    setSelectedDays(prevSelected => {
      if (prevSelected.includes(valueCheckbox)) {
        return prevSelected.filter(day => day !== valueCheckbox)
      } else {
        return [...prevSelected, valueCheckbox]
      }
    })
  }

  const handleRunSchedule = async () => {
    // let response: { data?: { message?: string | undefined } | void; error?: any }
    // await updateTask(task)
    // response = await runSchedule({
    //   taskId: taskId!,
    //   frequency: selectedFrequency,
    //   days: selectedDays,
    //   timeOptions: valueTimeOptions
    // })
    handleClose()
    addEmails && addEmails(prev => !prev)
    // if (response.data) {
    //   if (response.data.message) {
    //     toast.success(`${response.data.message}`)
    //   } else {
    //     toast.error('An error occurred.')
    //   }
    // } else if (response.error) {
    //   toast.error(`${response.error.data?.message}`)
    // }
  }

  const handleRemoveSchedule = async (id: number) => {
    let response: { data?: { message?: string | undefined } | void; error?: any }
    response = await removeSchedule(id)
    handleClose()

    if (response.data) {
      toast.success(`${response?.data?.message}`)
    } else if (response.error) {
      toast.error(`${response.error?.data?.message}`)
    }
  }

  useEffect(() => {
    setRunScheduleLoading(runScheduleLoading)
  }, [runSchedule, runScheduleLoading])

  useEffect(() => {
    if (isOpen) {
      setTaskId(task.id)
    }
  }, [isOpen])

  useEffect(() => {
    if (task && task.schedule_object) {
      setSelectedFrequency(task.schedule_object.frequency)
      setSelectedDays(task.schedule_object.days)
      setValueTimeOptions(task.schedule_object.timeOptions)
    } else {
      setSelectedFrequency('monthly')
      setSelectedDays([])
      setValueTimeOptions(scheduleInitial)
    }
  }, [task])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <FormControl>
          <Typography id='frequency' sx={{ fontSize: '22px', fontWeight: 700, color: '#000' }}>
            Download invoices every:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '15px',
              maxWidth: '280px',
              alignItems: 'center',
              padding: '10px 0px'
            }}
          >
            {days.map(day => (
              <span
                key={day}
                style={{
                  width: '25px',
                  textAlign: 'center',
                  borderRadius: '50%',
                  backgroundColor: selectedDay === day ? '#FFAFAF' : 'transparent',
                  cursor: 'pointer'
                }}
                onClick={() => setSelectedDay(day)}
              >
                {day}
              </span>
            ))}
          </Box>
          {/* <RadioGroup
            aria-labelledby='frequency'
            defaultValue='monthly'
            name='frequency-group'
            value={selectedFrequency}
            onChange={e => setSelectedFrequency(e.target.value as string)}
            sx={{ flexDirection: 'row' }}
          >
            {frequencyArray.map(frequency => (
              <FormControlLabel
                key={frequency}
                value={frequency}
                control={<Radio disabled={!!(task && task.schedule_object)} />}
                label={frequency.charAt(0).toUpperCase() + frequency.slice(1)}
              />
            ))}
          </RadioGroup> */}
          {/* <Box sx={{ display: selectedFrequency === 'daily' ? 'block' : 'none' }}> */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              showDaysOutsideCurrentMonth={false}
              slotProps={{
                actionBar: { actions: [] },
                calendarHeader: {},
                toolbar: {}
              }}
            />
          </LocalizationProvider> */}

          {/* <Typography sx={{ fontSize: '22px', fontWeight: 700, color: '#000', mt: '40px' }}>Day(s)</Typography>
            <FormGroup>
              {daysArray.map(day => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      value={day}
                      checked={selectedDays.includes(day)}
                      onChange={handleCheckboxChange}
                      disabled={!!(task && task.schedule_object)}
                    />
                  }
                  label={day.charAt(0).toUpperCase() + day.slice(1)}
                />
              ))}
            </FormGroup> */}
          {/* </Box> */}
          <Typography pb={2} sx={{ fontSize: '22px', fontWeight: 700, color: '#000' }}>
            Skip weekends:
            <Checkbox
              sx={{
                color: 'black',
                '&.Mui-checked': {
                  color: 'black'
                }
              }}
            />
          </Typography>
          <Typography sx={{ fontSize: '22px', fontWeight: 700, color: '#000' }}>Select time:</Typography>
          <Box
            // onClick={() => {
            //   if (task && task.schedule_object === null) handleChildOpen()
            // }}
            sx={{
              cursor: task && task.schedule_object === null ? 'pointer' : 'default',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              width: 'fit-content'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                height={'24px'}
                onClick={() =>
                  setHours(prev => {
                    if (prev === 12) {
                      return 1
                    } else {
                      return prev + 1
                    }
                  })
                }
              >
                <KeyboardArrowUpIcon
                  sx={{
                    cursor: 'pointer'
                  }}
                />
              </Box>
              <Box
                p={1}
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid grey',
                  borderRadius: '5px'
                }}
              >
                {hours}
              </Box>
              <Box
                height={'24px'}
                onClick={() =>
                  setHours(prev => {
                    if (prev === 1) {
                      return 12
                    } else {
                      return prev - 1
                    }
                  })
                }
              >
                <KeyboardArrowDownIcon
                  sx={{
                    cursor: 'pointer'
                  }}
                />
              </Box>
            </Box>
            :
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box
                height={'24px'}
                onClick={() =>
                  setMins(prev => {
                    if (prev === 60) {
                      // setHours(prev => prev + 1)
                      return 0
                    } else {
                      return prev + 1
                    }
                  })
                }
              >
                <KeyboardArrowUpIcon
                  sx={{
                    cursor: 'pointer'
                  }}
                />
              </Box>
              <Box
                p={1}
                sx={{
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid grey',
                  borderRadius: '5px'
                }}
              >
                {mins}
              </Box>
              <Box
                height={'24px'}
                onClick={() =>
                  setMins(prev => {
                    if (prev === 0) {
                      return 60
                    } else {
                      return prev - 1
                    }
                  })
                }
              >
                <KeyboardArrowDownIcon
                  sx={{
                    cursor: 'pointer'
                  }}
                />
              </Box>
            </Box>
            <Box
              p={1}
              sx={{
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F6F6F6',
                borderRadius: '5px'
              }}
              onClick={() =>
                setIsAm(prev => {
                  if (prev === 'AM') {
                    return 'PM'
                  } else {
                    return 'AM'
                  }
                })
              }
            >
              {isAm}
            </Box>
            {/* <Typography
              sx={{
                p: '20px',
                backgroundColor: '#F6F6F6',
                fontSize: '22px',
                fontWeight: 700,
                color: '#000'
              }}
            >
              {valueTimeOptions?.hours}
            </Typography>
            <Typography>:</Typography>
            <Typography
              sx={{
                p: '20px',
                backgroundColor: '#F6F6F6',
                fontSize: '22px',
                fontWeight: 700,
                color: '#000'
              }}
            >
              {valueTimeOptions?.minutes}
            </Typography>
            <Typography sx={{ fontSize: '22px', fontWeight: 700, color: '#000' }}>{amPmText}</Typography> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, mt: '14px' }}>
            {task && task.schedule === null && (
              <Button
                sx={{ textTransform: 'none', fontSize: '18px', fontWeight: '700' }}
                variant='contained'
                color='success'
                startIcon={<PlayArrowIcon />}
                onClick={handleRunSchedule}
              >
                Execute
              </Button>
            )}
            {task && task.schedule !== null && (
              <Button
                variant='contained'
                color='error'
                startIcon={<DeleteForeverOutlinedIcon />}
                onClick={() => handleRemoveSchedule(taskId!)}
              >
                Delete schedule
              </Button>
            )}
            {removeScheduleLoading && <CircularProgress />}
            <Button
              disabled={task.schedule == null}
              variant='contained'
              sx={{ textTransform: 'none', fontSize: '18px', fontWeight: '700' }}
              color='success'
              startIcon={<UndoOutlinedIcon />}
              onClick={handleClose}
            >
              Return
            </Button>
          </Box>
          <Modal open={openChildModal} onClose={handleChildClose}>
            <Box sx={{ ...style, alignItems: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['MultiSectionDigitalClock']}>
                  <DemoItem>
                    <MultiSectionDigitalClock
                      value={valueDayjs}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        setValueTimeOptions(prevState => ({
                          ...prevState,
                          hours: newValue!.hour(),
                          minutes: newValue!.minute()
                        }))
                        setValueDayjs(newValue)
                      }}
                      views={['hours', 'minutes']}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              <Button sx={{ mt: '14px' }} onClick={handleChildClose}>
                Ok
              </Button>
            </Box>
          </Modal>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default ScheduleModal
