import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useCreateTaskMutation, useLazyGetExecutingHistoryQuery } from 'api/taskAPI'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TablePagination,
  Typography
} from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import { initialTask } from '../constants/constants'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { toast } from 'react-toastify'
import { useAppSelector } from '../hooks/reduxAppSelector'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const HomePage = () => {
  const [getExecutingHistory, { data: executingHistory }] = useLazyGetExecutingHistoryQuery()
  const user = useAppSelector(state => state.userReducer)
  const [openModals, setOpenModals] = useState(Array(executingHistory?.length).fill(false))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [sortOrder, setSortOrder] = useState('asc')
  const [createTask, { data: response, isLoading: createTaskLoading, error: createTaskError }] = useCreateTaskMutation()
  const navigate = useNavigate()
  const createTaskHandler = () => {
    createTask(initialTask)
  }
  useEffect(() => {
    if (response && !createTaskLoading && !createTaskError) {
      navigate(`/home/tasks/${response!.id}`)
    }
  }, [response, createTaskLoading, createTaskError])

  const handleOpen = (index: number) => {
    const updatedOpenModals = [...openModals]
    updatedOpenModals[index] = true
    setOpenModals(updatedOpenModals)
  }

  const handleClose = (index: number) => {
    const updatedOpenModals = [...openModals]
    updatedOpenModals[index] = false
    setOpenModals(updatedOpenModals)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const sortedHistory = executingHistory
    ? [...executingHistory].sort((a, b) => {
        const dateA = new Date(a.date_time_with_timezone).getTime()
        const dateB = new Date(b.date_time_with_timezone).getTime()
        return sortOrder === 'asc' ? dateB - dateA : dateA - dateB
      })
    : []

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  useEffect(() => {
    getExecutingHistory()
  }, [])

  return (
    <Grid container display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={1}>
      {user?.tasks && user?.tasks.length > 0 ? (
        <>
          <Button variant={'outlined'} sx={{ borderColor: '#000000' }}>
            <Link style={{ textDecoration: 'none', color: '#000000' }} to={'/home/tasks'}>
              Go to tasks
            </Link>
          </Button>
          {executingHistory ? (
            sortedHistory.length < 1 ? (
              <Typography>No executing history.</Typography>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ mt: 5 }}>
                  <Table sx={{ border: '1px solid #D1D5DB' }} aria-label='simple table'>
                    <TableHead sx={{ backgroundColor: '#D1DEE0' }}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Status</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Task Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                          Timestamp
                          <IconButton aria-label='sort' onClick={toggleSortOrder}>
                            {sortOrder === 'asc' ? <ArrowDownwardOutlinedIcon /> : <ArrowUpwardOutlinedIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }} align='right'>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((task, index) => {
                        return (
                          <React.Fragment key={task.id}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell
                                component='th'
                                scope='row'
                                sx={{ fontWeight: 'bold', color: task.link && !task.error ? '#2e7d32' : '#d32f2f' }}
                              >
                                {task.status}
                              </TableCell>
                              <TableCell>{task.taskname}</TableCell>
                              <TableCell>
                                {new Date(task.date_time_with_timezone.toLocaleString()).toLocaleString()}
                              </TableCell>
                              <TableCell align='right'>
                                <Button
                                  variant='contained'
                                  color={task.link && !task.error ? 'success' : 'error'}
                                  startIcon={
                                    task.link && !task.error ? <CloudDownloadOutlinedIcon /> : <InfoOutlinedIcon />
                                  }
                                  onClick={() => task.error && handleOpen(index)}
                                  href={task.link || ''}
                                >
                                  {task.link && !task.error && 'Download the invoice'}
                                  {!task.link && task.error && 'View details'}
                                </Button>
                              </TableCell>
                            </TableRow>
                            {openModals[index] && (
                              <Modal
                                open={true}
                                onClose={() => handleClose(index)}
                                aria-labelledby='modal-modal-title'
                                aria-describedby='modal-modal-description'
                              >
                                <Box sx={style}>
                                  <Typography id='modal-modal-title' variant='h6' component='h2'>
                                    Text in a modal
                                  </Typography>
                                  <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                                    {task.error}
                                  </Typography>
                                </Box>
                              </Modal>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component='div'
                  count={sortedHistory.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </>
      ) : (
        <Grid container justifyContent={'center'} paddingY={3}>
          {!createTaskLoading && !createTaskError ? (
            <Grid container alignItems={'center'} flexDirection={'column'}>
              <Button
                variant='contained'
                color='secondary'
                sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}
                onClick={createTaskHandler}
              >
                <AddOutlinedIcon />
                Create Task
              </Button>
              <Typography>Click this button to start downloading your group invoices</Typography>
            </Grid>
          ) : createTaskLoading && !createTaskError ? (
            <CircularProgress />
          ) : (
            <Alert severity='error'>{(createTaskError as any).data.message}</Alert>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default HomePage
